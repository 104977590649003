html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Fieldwork", Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141d2b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#fancy-background {
  color: var(--background, #ffffff);
}

::selection {
  background: var(--primary, #2065f8);
  color: white;
}

svg.rsm-svg {
  background: #2a3240;
  border-radius: 6px;
}

:root {
  --toastify-color-dark: #111927 !important;
  --toastify-color-info: #04e4f4 !important;
  --toastify-color-success: #20e253 !important;
  --toastify-color-warning: #f6f000 !important;
  --toastify-color-error: #f80363 !important;
}
